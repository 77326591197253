import {useState } from 'react';
import Sidebar from '../../components/Assistant/Sidebar';
import TopBar from '../../components/Assistant/Topbar';
import moment from 'moment';
import MetaData from '../../layouts/MetaData';
import {useHistory} from 'react-router-dom';
import { getAssistantTimeReport } from '../../actions/assistantActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../layouts/Loader';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';


const AssistantTimeReport = () => {
 const dispatch = useDispatch();   
 const history = useHistory();

 const { user} = useSelector(state => state.auth);

 const [month, setMonth] = useState(moment().format('MM'));
 const [year, setYear] = useState("2024");

//  const [startMonth, setStartMonth] = useState(moment().format('MM'));
//  const [endMonth, setEndMonth] = useState(moment().add(1, "month").format('MM'));
 
 const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
 const [endMonth, setEndMonth] = useState(moment().format('MM'));
 
 const [filteredItem, setFilteredItem] = useState();

 const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);
 
 const [category, setCategory] = useState("RPM");

 let drIds = localStorage.getItem('myArray');
 

  const submitHandler = () => {
    
    dispatch(getAssistantTimeReport(drIds, month, year, category, startMonth, endMonth)); 
  }

  const downloadFile = (fileName) => {
    window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
  }

  const resetData = () => {
    dispatch({ type: RESET_TIME_REPORT_DATA });
    setFilteredItem("");
  }

  const options = []
  
  targets && targets.length > 0 && targets.filter((a, i) => targets.findIndex((s) => a?.assigned_patient_id?._id === s?.assigned_patient_id?._id) === i).map((trgt) => (
    options.push({value: trgt?.assigned_patient_id?._id, label: [trgt?.assigned_patient_id?.lastname, trgt?.assigned_patient_id?.firstname].join(" ")})
   )) 

  const searchPatient = (target) => {
    setFilteredItem(target.value);
  }


  return (
    <>
        <MetaData title="Time Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        <div className="row-display">
                            <div className="col-md-11">
                                <h5 style={{color: '#00A8A8'}}>
                                    <i className="bx bx-user"></i> <strong>&nbsp;Time Report </strong>
                                </h5>
                            </div>

                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                <i className='bx bx-arrow-back' ></i>
                            </button> &nbsp;
                                                      
                        </div>
                        <hr />

                    
                    <div className="row-display">
                    {category === 'RPM' ? <div className="col-md-3">
                        <label className="form-label" htmlFor="month">Select Month </label>
                            <select 
                                name="month" 
                                className="form-control"
                                value={month} 
                                onChange={e => setMonth(e.target.value)}
                                style={{width: '200px'}}
                                >
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div> : <div className="row-display">
                        <div>    
                        <label className="form-label" htmlFor="start">Start Month </label>
                            <select 
                                name="month" 
                                className="form-control"
                                value={startMonth} 
                                onChange={e => setStartMonth(e.target.value)}
                                style={{width: '200px'}}
                                >
                                <option value="01">January - 26</option>
                                <option value="02">February - 26</option>
                                <option value="03">March - 26</option>
                                <option value="04">April - 26</option>
                                <option value="05">May - 26</option>
                                <option value="06">June - 26</option>
                                <option value="07">July - 26</option>
                                <option value="08">August - 26</option>
                                <option value="09">September - 26</option>
                                <option value="10">October - 26</option>
                                <option value="11">November - 26</option>
                                <option value="12">December - 26</option>
                            </select>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div>
                        <label className="form-label" htmlFor="end">End Month </label>
                            <select 
                                name="month" 
                                className="form-control"
                                value={endMonth} 
                                onChange={e => setEndMonth(e.target.value)}
                                style={{width: '200px'}}
                                >
                                <option value="01">January - 25</option>
                                <option value="02">February - 25</option>
                                <option value="03">March - 25</option>
                                <option value="04">April - 25</option>
                                <option value="05">May - 25</option>
                                <option value="06">June - 25</option>
                                <option value="07">July - 25</option>
                                <option value="08">August - 25</option>
                                <option value="09">September - 25</option>
                                <option value="10">October - 25</option>
                                <option value="11">November - 25</option>
                                <option value="12">December - 25</option>
                            </select>
                         </div>
                    </div>}
                    
                        

                        <div className="col-md-3">     
                        <label className="form-label" htmlFor="year">Select Year </label>
                            <select 
                                name="year" 
                                className="form-control"
                                value={year} 
                                onChange={e => setYear(e.target.value)}
                                style={{width: '200px'}}
                                >
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>

                        <div className="col-md-2">     
                            <label className="form-label" htmlFor="category">Category <span style={{color: 'red'}}>*</span></label>
                            <select   
                                className="form-control"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="RPM">RPM</option>
                                <option value="CCM">CCM</option>
                            </select>
                            </div>

                            <div className="col-md-3 mt-2">     
                            <label>&nbsp;&nbsp;&nbsp;</label>
                            <button  
                                name="submit" 
                                type="submit" 
                                className="edit-pill mt-4"
                                onClick={submitHandler}
                            >
                                Generate Report
                            </button>
                        </div>
                      </div>
                      <hr />

                      {loading ? <Loader /> : <>
                        {targets && targets.length > 0 ? <>

                        <div>
                        <b style={{color: 'red'}}>Note : </b> Here you would see all targeted minutes added by nurses and assistant, of your assigned doctor in the selected month.          
                        <hr/>
                        <b className='mt-3 ml-3'>Filter By Patient:</b>
                        
                            <div className="col-md-4">   
                                <Select
                                    options={options}
                                    onChange={searchPatient}
                                    className="react-selectbox"
                                />
                            </div>
                        </div>            
                        <br />

                        <div className="row-display ml-2 mr-4">
                            <div className="col-md-12">
                            <div className="row-display">
                                <h5 className='title'>
                                    <i className='bx bxs-watch'></i> <strong>&nbsp;RPM Minutes Added </strong>
                                </h5>

                                <div style={{float: 'right', marginRight: '40px'}}>
                                    <div className="row"> 
                                        <button className="edit-pill ml-2" onClick={resetData}>Reset</button>
                                        </div>
                                    </div>
                            </div>     
                            
                            <div className="row-display-secondary">
                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div> 
                                </div>
                              </div>        
                            </div>
                            <br />

                                    {targets && targets.reverse().filter(item => filteredItem ? item?.assigned_patient_id?._id === filteredItem : item).map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" , "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient DOB: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Start Date: </label> 
                                                <label className="report-label ml-2"> {trgt?.startDate || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">End Date: </label> 
                                                <label className="report-label ml-2">{trgt?.endDate || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">Start Time: </label> 
                                                    <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">End Time: </label> 
                                                    <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {category} category</span>

                                                <p>
                                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                                    : null 
                                                    }
                                                </p>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>
                                        
                                        {trgt?.clinicalFollowUp ? <div className="container-fluid">
                                            <h5 className='title'>Clinical FollowUp</h5>
                                            <label className="profile-label">Q. How have you been managing your health ?</label>
                                            <p>{trgt?.clinicalFollowUp?.healthManagement}</p> 
                                            <br />
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any new problems or conditions ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isNewProblem === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. New Problems or Conditions Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.newProblemDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isWorseningConditions === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.worseningConditionsDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 2 */}
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problems Identified ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isMedicationProblems === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problem Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationProblemsDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall in last 30 days ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentFall === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentFallDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 3 */}
                                                <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Visit ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentlyHospitalized === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Details  ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentlyHospitalizedDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Request ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequest === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequestDetails}</p>
                                                </div>
                                            </div>
                                            
                                            <br />
                                            <label className="profile-label">Q. Any Additional Notes ?</label>
                                            <p>{trgt?.clinicalFollowUp?.additionalNotes}</p>
                                            
                                        </div> : null}
                                        
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                <div className="row-display">
                                                    <span className='text-white' 
                                                        style={{letterSpacing: '1px'}}> 
                                                        {trgt?.assigned_hr_id ? trgt?.assigned_hr_id?.lastname +" , "+ trgt?.assigned_hr_id?.firstname : trgt?.assigned_assistant_id?.lastname +" , "+ trgt?.assigned_assistant_id?.firstname +" ("+ trgt?.assigned_assistant_id?.role + " )"}
                                                    </span>
                                                </div>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1">{moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>

                                ))}
                            </> : ''}
                            </>}

                    </div>
                </div>
        
        </section>
    </>
  )
}

export default AssistantTimeReport