import { useState } from "react";
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import { submitPtSickness, deleteDisease } from '../../actions/adminActions';
import { useDispatch } from "react-redux";
import {useAlert } from 'react-alert';
import NotFound from "../NotFound";

const PatientSickness = ({isAuthenticated, patient}) => {

    const dispatch = useDispatch();
    const alert = useAlert();

    const [show, setShow] = useState(false);
    const [diseases, setDiseases] = useState([{ disease: '', type: patient?.patientType === 'CCM' ? 'chronic' : 'acute'}]); // State to store disease objects

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleInputChange = (index, event) => {
        const newDiseases = [...diseases];
        newDiseases[index][event.target.name] = event.target.value;
        setDiseases(newDiseases);
    };

    const handleAddDisease = () => {
        setDiseases([...diseases, { disease: '', type: patient?.patientType === 'CCM' ? 'chronic' : 'acute' }]); // Add a new empty disease object
    };

    const handleRemoveDisease = (index) => {
        // Filter out the disease at the specified index
        const updatedDiseases = diseases.filter((_, i) => i !== index);
        setDiseases(updatedDiseases);
      };

    const validateDiseases = () => {
        // Check if all fields are filled in
        const allFieldsFilled = diseases.every(d => d.disease.trim() !== '' && d.type.trim() !== '');
            
        // Check if the number of diseases meets the requirement
        const requiredDiseaseCount = (patient?.patientType === 'CCM' || patient?.patientType === 'both') ? 2 : 1;

        // return allFieldsFilled && diseases.length >= requiredDiseaseCount;
        if(patient?.sickness?.length === 0){
            return allFieldsFilled && diseases.length >= requiredDiseaseCount;
        } else {
            return allFieldsFilled && patient?.sickness?.length >= requiredDiseaseCount;
        }
    };



    const handleFormSubmit = () => {
        if (!validateDiseases()) {
            alert.error('Please ensure all fields are filled and that the correct number of diseases are added based on the patient type.');
            return;
          }

        dispatch(submitPtSickness(patient?._id, diseases));
    }

    const sicknessDeleteHandler = (Id) => {
        var confirmation = window.confirm(`Are you sure you want to delete patient's disease ?`);
        if(confirmation){
          dispatch(deleteDisease(Id, patient?._id));
        }
    }

    return (
    <div className="scrollablePathologies background-white">
        <div className="row-display">
            <b>Pathologies</b>
            {isAuthenticated === true ? <>
                <button className="edit-pill" onClick={handleShow}>Add Pathology</button>    
            </> : null}
        </div>

        {patient?.sickness?.length > 0 ? <>
            {patient?.sickness.map((item, index) => (
                <div key={index} className={item?.type === 'acute' ? 'coloredRow' : 'reddishRow'}>
                    
                    <p className="sicknessName">{item?.name} </p>
                    
                    <div className="row-display m-2 pb-2">
                        <p className={item?.type === 'acute' ? 'acuteSickness' : 'chronicSickness'}>{item?.type}</p>
                        <i>{item?.addedDateTime}</i>
                        {isAuthenticated === true ? <>
                            <button onClick={() => sicknessDeleteHandler(item?._id)} className={item?.type === 'acute' ? 'acuteSickness' : 'chronicSickness'}>
                                <i className="bx bx-trash"></i>
                            </button>
                        </> : null}
                    </div>
                </div>
            ))}
        </>: <>
        <NotFound title="No Added Yet" />
        </>}
        

        {/* Add Diseases */}
        <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <h5 className="title mt-2">Add Patient Disease(s)
          </h5>
          
          <button className="edit-pill" onClick={handleClose}><i className='bx bx-x'></i></button>
        </Modal.Header>
        <Modal.Body>
        <span><b style={{color: 'red'}}>Note : </b> For RPM Patients, Atleast one disease must be added.</span> <br/>
        <span> For CCM Patients, Atleast two diseases must be added.</span> <br />
        <span> Patient Enrolled in both, Atleast two diseases must be added.</span>
        <br />

        <hr />
            <div className="row-display">
                <div>
                    <h5 className="title">{patient?.lastname}, {patient?.firstname}</h5>
                    <p><b>{patient?.emrId}</b></p>
                </div>

                <div>
                    <h5>{moment(patient?.DOB).format('MM/DD/YYYY')}</h5>
                    <p><b>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</b></p>
                </div>
            </div>

            {diseases.map((diseaseObj, index) => (
                <div key={index} className="row ml-1 mb-3">
                <div>
                <label className="form-label">Disease Name</label>
                <input
                    type="text"
                    name="disease"
                    className="form-control"
                    placeholder="Enter Disease"
                    style={{width: '280px'}}
                    value={diseaseObj.disease}
                    onChange={(e) => handleInputChange(index, e)}
                />
                </div>
                &nbsp;&nbsp;
                <div>
                <label className="form-label">Disease Type</label>
                    <select
                        name="type"
                        value={diseaseObj.type}
                        className="form-control"
                        style={{width: '150px'}}
                        disabled={patient?.patientType === 'CCM' ? true : false}
                        onChange={(e) => handleInputChange(index, e)}
                    >
                        <option value="acute">Acute</option>
                        <option value="chronic">Chronic</option>
                    </select>
                </div>

                {diseases?.length > 1 ? <>
                    &nbsp;&nbsp;
                    <button 
                        style={{backgroundColor: 'red'}} 
                        className="edit-pill mt-4" onClick={() => handleRemoveDisease(index)}>
                        <i className="bx bx-trash"></i>
                    </button>
                </> : null}
            </div>
            ))}

            <div className="ml-2" style={{float: 'right'}}>
                <label className="form-label">{" "}</label>
                <button style={{backgroundColor: 'green'}} className="edit-pill" onClick={handleAddDisease}>
                    + Add Disease
                </button>
            </div>
            <br /><br />

            <hr />
            <button style={{float: 'right'}} onClick={handleFormSubmit} className="edit-pill">Submit Disease(s)</button>
            
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PatientSickness