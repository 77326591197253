import { useEffect, useState, useRef} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { Link, useHistory } from 'react-router-dom';
import { getHrLists, getDoctors, deleteHRMinutes , getDoctorPatients} from '../../actions/adminActions';
import { useSelector, useDispatch } from 'react-redux';
import { getTimeReportCCMByHR, getTimeReportCCMByDR,
     getTimeReportCCMByAssistant,
     getTimeReportCCMByAll,
     getTimeReportCCMByPatient
 } from '../../actions/HRActions';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import TimeReportPDF from '../../components/TimeReportPDF';
import Loader from '../../layouts/Loader';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import {getAssistants} from '../../actions/assistantActions';
import Select from 'react-select';
import {Badge} from 'react-bootstrap';
import { useAlert } from 'react-alert';


const TimeReportCCM = () => {
  const alert = useAlert();  
  const history = useHistory();
  const dispatch = useDispatch();
  const [reportBy, setReportBy] = useState('patient');
  const [month, setMonth] = useState(moment().subtract(1, "month").format('MM'));
  const [endMonth, setEndMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState('2024');
  const [hrId, setHRId] = useState('');
  const [drId, setDRId] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [patientId, setPatientId] = useState('');

  const { hrs } = useSelector(state => state.hrslist);
  const { doctors } = useSelector(state => state.doctor);
  const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);
  const [reportCategory, setReportCategory] = useState('CCM');
  const [filterBy, setFilterBy] = useState('ccmBoth');
  const { assistants } = useSelector(state => state.assistantsList);
  const { doctorpatients } = useSelector(state => state.docPatients);


  useEffect(() =>{

    dispatch(getHrLists());
    dispatch(getDoctors(50, 1));
    dispatch(getAssistants());

    if(drId){
        dispatch(getDoctorPatients(drId, filterBy));
    }

    dispatch({ type: RESET_TIME_REPORT_DATA });
  }, [dispatch, drId])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
            pageStyle:"A5",
            documentTitle:  "Time Report",
            content: () => componentRef.current,
    });

    const patientOptions = []
    {doctorpatients && doctorpatients.map((patient) => (
        patientOptions.push({ value: patient?._id, 
            label: [patient?.lastname, patient?.firstname, moment(patient?.DOB).format("MM/DD/YYYY") ].join(" "),
            isBlocked: patient?.block
        })
    ))}

    const getPatientProfile = (patient) => {
        setPatientId(patient.value);
    }


  const submitHandler = () => {
    if(reportBy === 'all'){
        dispatch(getTimeReportCCMByAll(month, year, reportCategory, endMonth)); 
    }

    else if (reportBy === 'patient'){
        if(!patientId){
            alert.error('Please select patient');
            return
        }
        else {
            dispatch(getTimeReportCCMByPatient(patientId, month, endMonth, year, 400));
        }
    }
    else if(reportBy === 'hr'){
        if(!hrId){
            alert.error('Please select nurse');
            return;
        } 
        else {
             dispatch(getTimeReportCCMByHR(hrId, month,  endMonth, year, 300)); 
        }
    }
    else if(reportBy === 'doctor'){
        if(!drId){
            alert.error('Please select doctor');
            return;
        } else {
        dispatch(getTimeReportCCMByDR(drId, month, year, endMonth)); 
        return;
        }
     } else if(reportBy === 'assistant'){
        if(!assistantId){
            alert.error('Please select assistant');
            return;
        } else {
        dispatch(getTimeReportCCMByAssistant(assistantId, month, endMonth, year)); 
        return;
        }
     }
    }


    const resetData = () => {
        dispatch({ type: RESET_TIME_REPORT_DATA });
    }

    const downloadFile = (fileName) => {
        window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
    }

    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: '46px', // Adjust the height here as needed
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.data.isBlocked ? 'red' : 'black', // Color based on isBlocked property
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.data.isBlocked ? 'red' : 'black', // Color for single value display
        }),
      };

    const deleteHandler = (Id) => {
        var confirmation = window.confirm(`Are you sure you want to delete minutes ?`);
            if(confirmation){
                dispatch(deleteHRMinutes(Id));
            }
        }


  return (
    <>
        <MetaData title="Time Report | CCM"/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />

            <div className="shadow-lg background-white m-3">
            <div className='p-3'>
                <div className="home-content">

                <div className="row-display">
                    <h5 className='title'>
                        <i className='bx bx-filter'></i> 
                        &nbsp;Time Report for CCM 
                    </h5>

                    <div className="row-display">
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back'></i>
                        </button>
                        &nbsp;&nbsp;
                        <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                        </Link>
                        <Link to="/Admin/Report/RPM" className="link m-2">
                            RPM Time Report
                        </Link>
                    </div>   
                </div>
                <hr />

                <div className="row cardWrapper">
                    <div className={`cardButton ${reportBy === "all" ? "cardActive" : ""}`}
                        onClick={() => setReportBy("all")}>
                            <i className="fas fa-list-ol" style={{color: '#02457A', fontSize: '66px'}}></i>
                            <p> By All </p>
                    </div>

                    <div className={`cardButton ${reportBy === "patient" ? "cardActive" : ""}`}
                        onClick={() => setReportBy("patient")}>
                            <i className="fas fa-user" style={{color: '#02457A', fontSize: '66px'}}></i>
                            <p> By Patient </p>
                    </div>

                    <div className={`cardButton ${reportBy === "doctor" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("doctor")}>
                        <i className="fas fa-user-md" style={{color: '#02457A', fontSize: '66px'}}></i>
                        <p> By Provider </p>
                    </div>
                    
                    
                    <div className={`cardButton ${reportBy === "hr" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("hr")}>
                        <i className="fas fa-users" style={{color: '#02457A', fontSize: '66px'}}></i>
                        <p> By Nurse </p>
                    </div>

                    <div className={`cardButton ${reportBy === "assistant" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("assistant")}>
                        <i className="fas fa-headset" style={{color: '#02457A', fontSize: '66px'}}></i>
                        <p> By Assistant </p>
                    </div>
                </div>
                <br />

                {/* first row */}
                <div className="row">
                    <div className="col-md-5">
                    {reportBy === 'all' ? <>
                    <label className="form-label" htmlFor="from">By All Entities <span style={{color: 'red'}}>*</span></label>
                    <p><strong>Please select start date & end date</strong></p>
                    
                    </> : 
                    reportBy === 'patient' ? <>
                    <div className="row-display">
                    
                    <div className="col-6">  
                    <label className="form-label" htmlFor="from">Select Doctor </label>
                    <select 
                        className="form-control"
                        value={drId}
                        onChange={(e) => setDRId(e.target.value)}
                        >
                        <option>Select Doctor</option>
                        {doctors && doctors.filter(item => item?.block === false).map((doctor, index) => (
                                <option value={doctor?._id} key={index}>{doctor?.lastname +" , "+ doctor?.firstname} &nbsp;&nbsp;&nbsp; {doctor?.npinumber} </option>
                            ))} 
                    </select>
                    </div>

                    <div className="col-6">            
                    <label className="form-label" htmlFor="from">Select Patient </label>
                        {drId && <Select 
                            className="react-selectbox"
                            options={patientOptions}
                            onChange={getPatientProfile}
                            styles={customStyles}
                        />
                        } 
                    </div>
                    </div>               
                    </> :
                    reportBy === 'hr' ? <>    
                    <label className="form-label" htmlFor="from">Select Nurse <span style={{color: 'red'}}>*</span></label>
                    <select 
                        className="form-control"
                        value={hrId}
                        onChange={(e) => setHRId(e.target.value)}
                        >

                        <option>Select Nurse</option>
                        {hrs && hrs.map((hr, index) => (
                                <option value={hr?._id} key={index}> {hr?.lastname +" ,"+ hr?.firstname} &nbsp;&nbsp;&nbsp; {hr?.npinumber} </option>
                            ))} 
                    </select>                        
                    </>
                    : reportBy === 'assistant' ? <>
                    <label className="form-label" htmlFor="from">Select Assistant <span style={{color: 'red'}}>*</span></label>
                    <select 
                        className="form-control"
                        value={assistantId}
                        onChange={(e) => setAssistantId(e.target.value)}
                        >

                        <option>Select Assistant</option>
                        {assistants && assistants.map((assistant, index) => (
                                <option value={assistant?._id} key={index}> {assistant?.lastname +" ,"+ assistant?.firstname} &nbsp;&nbsp;&nbsp; {assistant?.npinumber} </option>
                            ))} 
                    </select>

                    </>: <>
                    <label className="form-label" htmlFor="from">Select Organization <span style={{color: 'red'}}>*</span></label>
                    <select 
                        className="form-control"
                        value={drId}
                        onChange={(e) => setDRId(e.target.value)}
                        >
                        <option>Select Organization</option>
                        {doctors && doctors.filter(item => item?.block === false).map((doctor, index) => (
                                <option value={doctor?._id} key={index}>
                                    {doctor?.lastname +" , "+ doctor?.firstname} &nbsp;&nbsp;&nbsp;{doctor?.npinumber} </option>
                            ))} 
                    </select>                        
                    </>}
                </div>
                    
                <div className="col-md-2">
                <label className="form-label" htmlFor="month">Start Month </label>
                    <select 
                      name="month" 
                      className="form-control"
                      value={month} 
                      onChange={e => setMonth(e.target.value)}
                      >
                      <option value="01">January - 26</option>
                      <option value="02">February - 26</option>
                      <option value="03">March - 26</option>
                      <option value="04">April - 26</option>
                      <option value="05">May - 26</option>
                      <option value="06">June - 26</option>
                      <option value="07">July - 26</option>
                      <option value="08">August - 26</option>
                      <option value="09">September - 26</option>
                      <option value="10">October - 26</option>
                      <option value="11">November - 26</option>
                      <option value="12">December - 26</option>
                    </select>
                </div>

                <div className="col-md-2">
                <label className="form-label" htmlFor="month">End Month </label>
                    <select 
                      name="month" 
                      className="form-control"
                      value={endMonth} 
                      onChange={e => setEndMonth(e.target.value)}
                      >
                      <option value="01">January - 25</option>
                      <option value="02">February  - 25</option>
                      <option value="03">March  - 25</option>
                      <option value="04">April  - 25</option>
                      <option value="05">May  - 25</option>
                      <option value="06">June  - 25</option>
                      <option value="07">July  - 25</option>
                      <option value="08">August  - 25</option>
                      <option value="09">September  - 25</option>
                      <option value="10">October  - 25</option>
                      <option value="11">November  - 25</option>
                      <option value="12">December  - 25</option>
                    </select>
                </div>

                <div className="col-md-2">
                <label className="form-label" htmlFor="year">Select Year </label>
                    <select 
                      name="year" 
                      className="form-control"
                      value={year} 
                      onChange={e => setYear(e.target.value)}
                      >
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                </div>

                   
                    <div className="col-md-1">     
                    <label>&nbsp;&nbsp;&nbsp;</label>
                    <button  
                        name="submit" 
                        type="submit" 
                        className="edit-pill"
                        onClick={submitHandler}
                    >
                    <i className='bx bx-search'></i> Find
                    </button>
                      </div>
                     </div>

               </div>
              </div>

               {/* First Row Ends Here */}
               {loading ? <Loader /> : <>
                    {targets && targets.length > 0 ? <>
                                    
                        <br />
                        <div className="row-display">
                            <div className="col-md-11">
                            <div className="row-display">
                                <h5 style={{color: '#00A8A8'}}>
                                    <i className='bx bxs-watch'></i> <strong>&nbsp;CCM Minutes Added </strong>
                                </h5>

                                <div style={{float: 'right', marginRight: '40px'}}>
                                    <div className="row">
                                    <div
                                        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
                                    > 
                                    <TimeReportPDF ref={componentRef} 
                                        targets={targets}
                                        interactiveMinutes={totalInteractiveMinutes}
                                        nonInteractiveMinutes={totalNonInteractiveMinutes}
                                        timeSpent={totalTime}
                                        month= {month}
                                        year= {year}
                                        category={reportCategory}
                                        endMonth={endMonth}
                                    />
                                    </div>
                                    
                                    <button onClick={handlePrint} 
                                        className="edit-pill">
                                            <i className='bx bxs-file-pdf' style={{color:'#F40F02'}}></i> Download Report .PDF
                                    </button>
                                    
                                    <button className="edit-pill ml-2" onClick={resetData}>Reset</button>
                                        </div>
                                    </div>


                            </div>     
                            
                            <div className="row-display-secondary">
                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div> 
                                </div>
                              </div>        
                            </div>
                            <br />

                                    {targets && targets.map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">EHR-ID: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.emrId}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" , "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient DOB: </label> 
                                                <label className="report-label ml-2">{trgt?.assigned_patient_id?.DOB && trgt?.assigned_patient_id?.DOB}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Account Status: </label> 
                                                <label style={{color: trgt?.assigned_patient_id?.block === true ? 'red' : 'green', fontWeight: 'bold'}}>
                                                    {trgt?.assigned_patient_id?.block === true ? 'Deactivated' : 'Active'}
                                                </label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Start Date: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM-DD-YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">End Date: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM-DD-YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S - Time: </label> 
                                                    <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E - Time: </label> 
                                                    <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                                <p>
                                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                                    : null 
                                                    }
                                                </p>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>

                                        {trgt?.clinicalFollowUp ? <div className="container-fluid">
                                            <h5 className='title'>Clinical FollowUp</h5>
                                            <label className="profile-label">Q. How have you been managing your health ?</label>
                                            <p>{trgt?.clinicalFollowUp?.healthManagement}</p> 
                                            <br />
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any new problems or conditions ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isNewProblem === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. New Problems or Conditions Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.newProblemDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isWorseningConditions === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.worseningConditionsDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 2 */}
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problems Identified ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isMedicationProblems === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problem Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationProblemsDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall in last 30 days ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentFall === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentFallDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 3 */}
                                                <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Visit ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentlyHospitalized === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Details  ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentlyHospitalizedDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Request ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequest === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequestDetails}</p>
                                                </div>
                                            </div>
                                            
                                            <br />
                                            <label className="profile-label">Q. Any Additional Notes ?</label>
                                            <p>{trgt?.clinicalFollowUp?.additionalNotes}</p>
                                            
                                        </div> : null}
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                <div className="row-display">
                                                    <span className='text-white' 
                                                        style={{letterSpacing: '1px'}}> 
                                                        {trgt?.assigned_assistant_id ? 
                                                            trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname 
                                                            : trgt?.assigned_doctor_id ? 'Dr. '+ trgt?.assigned_doctor_id?.lastname +", "+ trgt?.assigned_doctor_id?.firstname 
                                                            : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname
                                                        }
                                                    </span>

                                                    <span className="deleteButton" onClick={() => deleteHandler(trgt?._id)}>
                                                        <small><i className='bx bxs-minus-circle'></i> Delete</small>
                                                    </span>
                                                </div>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1">{moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>

                                ))}
                            </> : ''}
                            </>}
            </div>
            </section>
    </>
  )
}

export default TimeReportCCM