import { useState, useEffect } from 'react';
import MetaData from '../../layouts/MetaData';
import HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import { jsPDF } from 'jspdf';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeReportMonthlyLog } from '../../actions/HRActions';
import Loader from '../../layouts/Loader';
import { useAlert } from 'react-alert';
import { MONTHLY_LOG_REPORT_FAIL } from '../../constants/HRConstants';


const HRTimeReportMonthlyLog = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();

  const { assignedDoctors } = useSelector(state => state.hrStats);
  const { loading, count, patientlist } = useSelector(state => state.timeReportMonthlyLog);

  const [selectedRows, setSelectedRows] = useState([]);
  const [doctorId, setDoctorId] = useState('');
  const [month, setMonth] = useState(moment().format('MM'));
  const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
  const [endMonth, setEndMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState("2024");
  const [reportCategory, setReportCategory] = useState('RPM');
  const [pagination, setPagination] = useState(10);

  useEffect(() => {
    dispatch({
      type: MONTHLY_LOG_REPORT_FAIL
    })

  }, [pagination]); // Dependency array with pagination

  const handleRowCheck = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const submitHandler = () => {
    resetSelection();
    if (!doctorId) {
      alert.error('Please select Doctor');
      return;
    } else {
      if (reportCategory === 'RPM') {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, month, year, pagination));
      } else {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, startMonth, year, pagination, endMonth)); 
      }
    }
  }

  const updateListPagination = () => {
    setPagination(pagination + 20);
    if (reportCategory === 'RPM') {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, month, year, pagination));
      } else {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, startMonth, year, pagination, endMonth)); 
      }
  }

  const resetSelection = () => {
    setSelectedRows([]);
  }

  const handleDownloadPDF = () => {
    // Implement PDF generation here for selectedRows
    const margin = 40;
    const footerHeight = 10;

    const renderFooter = (doc, pageNumber) => {
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(10);
      doc.setTextColor(150, 150, 150); // Light gray color
      doc.text(`Page ${pageNumber}`, margin, pageHeight - margin / 2);
      doc.line(margin, pageHeight - margin, pageWidth - margin, pageHeight - margin);
      doc.setTextColor(0, 0, 0); // Light gray color
    };

    selectedRows.forEach(rowId => {
      const doc = new jsPDF();
      const text = 'Time Tracking Report';
      let pageNumber = 1;
      
      let y = 110;
      const maxWidth = doc.internal.pageSize.getWidth() - 30; 

      const printMinuteEntry = (entry, index) => {
      
      doc.text(`(${index + 1})`, 10, y);

      doc.text(20, y, `${entry?.assigned_hr_id ? entry?.assigned_hr_id?.lastname +', '+ 
        entry?.assigned_hr_id?.firstname : entry?.assigned_assistant_id ? entry?.assigned_assistant_id?.lastname +', '+ 
        entry?.assigned_assistant_id?.firstname : entry?.assigned_doctor_id ? entry?.assigned_doctor_id?.lastname +', '+
        entry?.assigned_doctor_id?.firstname : null}`, { maxWidth: maxWidth });
      
      y+= 6
      doc.text('Conclusion : ', 20, y);
      y+= 6
      doc.setTextColor(64, 64, 64); // Official color

    // Split the conclusion text into multiple lines
    const conclusionLines = doc.splitTextToSize(entry?.conclusion, maxWidth);
    doc.text(20, y, conclusionLines);

    y += conclusionLines.length * 7; 
  
    doc.setTextColor(0, 0, 0); 
    doc.text('Start Date : ', 20, y);
    doc.text('Start Time : ', 60, y);
    doc.text('Minutes Added ', 110, y);
    doc.text('Minutes Category ', 160, y);


    y += 6;
    doc.text(`${entry?.startDate}`, 20, y);
    doc.text(`${entry?.startTime || null}`, 60, y);
    
    doc.setFontSize(18);
    doc.text(`${entry?.timeSpentInMinutes}`, 120, y);
    doc.setFontSize(10);

    if (entry.interactiveMinutes === true) {
      doc.setTextColor(0, 100, 0); // Green color
      doc.text(`Interactive Minutes`, 160, y);
      doc.setTextColor(0, 0, 0);
    } else {
      doc.setTextColor(255, 0, 0); // Red color
      doc.text(`Non-Interactive Minutes`, 160, y);
      doc.setTextColor(0, 0, 0);
    }

    y += 10;
    doc.text('End Date : ', 20, y);
    doc.text('End Time : ', 60, y);    

    y += 6;
    doc.text(`${entry?.endDate}`, 20, y);
    doc.text(`${entry?.endTime || null}`, 60, y);
    
    y+= 10
    doc.setDrawColor(200);
    doc.line(startX, y, endX, y);
    y+= 10


    if (y + footerHeight + 40 > doc.internal.pageSize.getHeight() - margin) {
      renderFooter(doc, pageNumber);
      doc.addPage();
      pageNumber++;
      y = margin; // Reset y-coordinate for new page
    }

  };
      
      // Get the page width (A4 width is 210mm by default)
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;

      // Draw a line
      const startX = 10;  // 10 units from the left edge
      const endX = doc.internal.pageSize.getWidth() - 10;  // 10 units from the right edge
            
      const rowData = patientlist.find(item => item?._id === rowId); // Assuming data has unique ids
      
      doc.setTextColor(0, 168, 168); // Official color
      doc.setFontSize(16);
      doc.text('Remote Monitoring', 10, 10);

      doc.setTextColor(0, 0, 0); // Official color
      doc.setFontSize(14);


      doc.text(`Time Tracking Report of ${reportCategory}`, x, 25);
      doc.text(`Created At : ${moment().tz("America/New_York").format("MM/DD/YYYY")}`, x, 35);
      doc.line(startX, 40, endX, 40);

      doc.setTextColor(0, 168, 168); // Official color
      doc.setFontSize(14);
      doc.text('Patient Details : ', 10, 48);

      doc.setTextColor(0, 0, 0); // Official color
      doc.setFontSize(10);
      
      doc.text('EHR-ID', 10, 55);
      doc.text(`${rowData?.emrId}`, 10, 62);

      doc.text('Patient Name', 60, 55);
      doc.text(`${rowData?.lastname +', '+ rowData?.firstname}`, 60, 62);

      doc.text('DOB', 130, 55);
      doc.text(`${moment(rowData?.DOB).format("MM/DD/YYYY")}`, 130, 62);

      doc.text('Gender', 160, 55);
      doc.text(`${rowData?.gender === 'male' ? 'Male' : 'Female'}`, 160, 62);


      doc.setTextColor(0, 168, 168); // Official color
      doc.setFontSize(14);
      doc.text('Time Summary', 10, 72);

      doc.setTextColor(0, 0, 0); // Official color
      doc.setFontSize(10);
      
      doc.text('Month & Year', 10, 80);
      doc.text(`${reportCategory === 'CCM' ? startMonth +'-'+ year +' to '+ endMonth +'-'+ year : month +'-'+ year}`, 10, 87);

      doc.text('Total Minutes Added', 60, 80);
      doc.text(`${rowData?.totalMinutesSpent}` + ' mins' , 60, 87);

      doc.setTextColor(0, 100, 0); // for green color
      doc.text('Interactive Minutes', 110, 80);
      doc.text(`${rowData?.totalInteractiveMinutes}` + ' mins' , 110, 87);

      doc.setTextColor(255,0, 0); // for red color
      doc.text('Non Interactive Minutes', 160, 80);
      doc.text(`${rowData?.totalNonInteractiveMinutes}` + ' mins' , 160, 87);

      doc.line(startX, 95, endX, 95);
      
      doc.setTextColor(0, 168, 168); // Official color
      doc.setFontSize(14);
      doc.text('Minutes Added :', 10, 105);

      doc.setTextColor(0, 0, 0); // Official color
      doc.setFontSize(10);

      // Print each minute entry
      // rowData?.minutesEntries.map(printMinuteEntry, index);
      rowData?.minutesEntries.map((entry, index) => printMinuteEntry(entry, index));

      doc.save(`${rowData?.firstname +', '+ rowData?.lastname} CCM Time Report.pdf`);
    });
  };

  return (
    <>
      <MetaData title="Multi Patient Time Report" />
      <HRSidebar />

      <section className="home-section">
        {/* TopBar */}
        <HRTopBar />

        <br />
        <div className="shadow-lg p-3 m-2 background-white rounded">
          <div className="home-content">
            <div>
              <div className="row-display">

                <h5 className='title'>
                  <i className='bx bx-filter'></i> 
                  &nbsp;Time Report for RPM & CCM 
                </h5>

                <div className="row-display">
                  <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                    <i className='bx bx-arrow-back'></i>
                  </button>

                  &nbsp;&nbsp;
                  <Link to="/Nurse/Report/TimeReport" className="link">
                    Individual Patient Time Report
                  </Link>

                  &nbsp;&nbsp;
                  <Link to="/Nurse/Dashboard" className="btn btn-secondary btn-sm">
                    <i className='bx bxs-home'></i>
                  </Link>
                </div>
              </div>
              <hr />


              <div className="row-display mt-3 reportFormFields">
                <div>
                  <label className="form-label" htmlFor="year">Mins. Category </label>
                  <select
                    name="category"
                    className="form-control"
                    value={reportCategory}
                    onChange={e => setReportCategory(e.target.value)}
                    style={{ width: '200px' }}
                  >
                    <option value="RPM">RPM</option>
                    <option value="CCM">CCM</option>
                  </select>
                </div>

                <div>
                  <label className="form-label" htmlFor="from">Select Doctor </label>
                  <select 
                    className="form-control" 
                    value={doctorId} 
                    onChange={e => setDoctorId(e.target.value)}
                    style={{ width: '200px' }}
                    >
                    <option value="default">Select Doctor</option>
                    {assignedDoctors && assignedDoctors.map((doctor, index) => (
                      <option key={index} value={doctor?._id}>
                        Dr. {doctor.firstname} {doctor?.lastname}
                      </option>
                    ))}
                  </select>
                </div>

                {reportCategory === 'RPM' ?
                  <div>
                    <label className="form-label" htmlFor="month">Select Month </label>
                    <select
                      name="month"
                      className="form-control"
                      value={month}
                      onChange={e => setMonth(e.target.value)}
                      style={{ width: '200px' }}
                    >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div> :
                  // CCM minutes 
                  <>
                    <div>
                      <label className="form-label" htmlFor="month">Start Month </label>
                      <select
                        name="month"
                        className="form-control"
                        value={startMonth}
                        onChange={e => setStartMonth(e.target.value)}
                        style={{ width: '200px' }}
                      >
                        <option value="01">January - 26</option>
                        <option value="02">February - 26</option>
                        <option value="03">March - 26</option>
                        <option value="04">April - 26</option>
                        <option value="05">May - 26</option>
                        <option value="06">June - 26</option>
                        <option value="07">July - 26</option>
                        <option value="08">August - 26</option>
                        <option value="09">September - 26</option>
                        <option value="10">October - 26</option>
                        <option value="11">November - 26</option>
                        <option value="12">December - 26</option>
                      </select>
                    </div>


                    <div>
                      <label className="form-label" htmlFor="month">End Month </label>
                      <select
                        name="month"
                        className="form-control"
                        value={endMonth}
                        onChange={e => setEndMonth(e.target.value)}
                        style={{ width: '200px' }}
                      >
                        <option value="01">January - 25</option>
                        <option value="02">February - 25</option>
                        <option value="03">March - 25</option>
                        <option value="04">April - 25</option>
                        <option value="05">May - 25</option>
                        <option value="06">June - 25</option>
                        <option value="07">July - 25</option>
                        <option value="08">August - 25</option>
                        <option value="09">September - 25</option>
                        <option value="10">October - 25</option>
                        <option value="11">November - 25</option>
                        <option value="12">December - 25</option>
                      </select>
                    </div>
                  </>
                }


                <div>
                  <label className="form-label" htmlFor="year">Select Year </label>
                  <select
                    name="year"
                    className="form-control"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                    style={{ width: '200px' }}
                  >
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>

                <div className="mt-1" style={{ float: 'right' }}>
                  <button
                    name="submit"
                    type="submit"
                    className="edit-pill-2 mt-4"
                    onClick={submitHandler}
                  >
                    <i className='bx bx-paper-plane'></i> Generate Report
                  </button>
                </div>
              </div>  {/* First Row Ends Here */}
              <hr />

              
                
                <div>
                {patientlist && patientlist?.length > 0 ? <>
                  <div className="row-display ml-2">
                    <div>
                      <h5 className="title">{count && count < 10 ? '0'+count : count} Results Found.</h5>
                      <p className="m-0 p-0">You can download multiple reports at a time, check the rows you want to download.</p>
                    </div>  

                    {selectedRows?.length > 0 ? <>
                      <div>
                        <button 
                            className="edit-pill mr-3 m-2" 
                            onClick={handleDownloadPDF} 
                            style={{backgroundColor: 'green'}}
                        >
                            Download {selectedRows?.length < 10 ? '0'+selectedRows?.length : selectedRows?.length} Patient(s) Report
                        </button>

                        <button 
                            className="edit-pill mr-3 m-2" 
                            onClick={resetSelection} 
                            style={{backgroundColor: 'red'}}
                        >
                            Reset
                        </button>
                        </div>
                    </> : null}
                    </div>
                </> : null}
                
              </div>

              {/* Report results starts from here */}
              {loading ? <Loader /> : <>
                {patientlist && patientlist?.length > 0 ? <>
                  <div className="col-md-12">
                    <br />
                    <table className="styled-table">
                      <thead align="center">
                        <tr>
                          <th></th>
                          <th>Sr.</th>
                          <th>PatientName & EHR-ID</th>
                          <th>D.O.B</th>
                          <th>Total Minutes</th>
                          <th>Month & Year</th>
                          <th>Category</th>
                        </tr>
                      </thead>

                      <tbody>
                      {patientlist && patientlist.map((pts, index) => (
                        <tr key={index} className={pts?.totalMinutesSpent && pts?.totalMinutesSpent > 0 ? 'coloredRow' : 'reddishRow'}>
                             <td>
                                 <label className="checkbox-wrapper">
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(pts?._id)}
                                        onChange={() => handleRowCheck(pts?._id)}
                                        disabled={pts?.totalMinutesSpent > 0 ? false : true}
                                    />
                                <div className="checkbox-custom"></div>
                                </label>
                              </td>

                            <td>{index + 1}</td>

                            <td>
                              <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?._id}}} className="link">
                                {pts?.lastname + " , "+  pts?.firstname} </Link>
                                <p><b>{pts?.emrId}</b></p>      
                            </td>

                            <td> <b>{moment(pts?.DOB).format("MM-DD-YYYY")}</b> </td>

                            <td><b>{pts?.totalMinutesSpent}</b></td>

                            <td><b>{month && year}</b></td>

                            <td><b>{reportCategory && reportCategory}</b></td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                    
                    <br />
                    {patientlist?.length < count && <>
                            <div className="text-center">
                                <button className="edit-pill" onClick={() => updateListPagination()}>Load more</button>
                            </div>
                        </> }
                  </div>
                </> : null
                }


              </>
              }
            </div>
          </div>
        </div>


      </section>
    </>
  )
}

export default HRTimeReportMonthlyLog