import React from 'react';
import moment from 'moment';


export default class MyDocument extends React.PureComponent {
    render() {

        const { targets } = this.props;
        const { interactiveMinutes } = this.props;
        const {nonInteractiveMinutes} = this.props;
        const { timeSpent } = this.props;
        const { month } = this.props;
        const { year } = this.props;
        const { category } = this.props;
        const {endMonth} = this.props;

      return (
        <div>
            <div className="row-display">
                <h1 style={{color: '#00A8A8'}}><b>Remote Monitoring</b></h1>    
            </div>

            <br/><br/><br/>
            <h3 className="text-center">Time Tracking Report of {category}</h3>
            <h4 className="text-center">Created At : {moment().tz("America/New_York").format("MM/DD/YYYY")}</h4>       
            <br /><br />

            <p className="display-4" style={{color: '#00A8A8'}}><b>Time Summary</b></p>
            {/* <hr /> */}

            <div className="row-display">
                <div>
                    {category === 'RPM' ? <>
                    <h5>Month & Year</h5>
                    <h4>{month} - {year}</h4>
                    </> : <>
                    <h5>Month & Year</h5>
                    <h4>{month +'-'+ year +' to '+ endMonth +'-'+ year} </h4>
                    </>}
                    
                </div>

                <div>
                    <h5>Total Minutes Added</h5>
                    <h4>{timeSpent} mins</h4>
                </div>

                <div style={{color: 'green'}}>
                    <h5>Interactive Minutes</h5>
                    <h4>{interactiveMinutes} mins</h4>
                </div>

                <div style={{color: 'red'}}>
                    <h5>Non Interactive Minutes</h5>
                    <h4>{nonInteractiveMinutes} mins</h4>
                </div>
            </div>
            <hr/>

            <h2 style={{color: '#00A8A8'}}><b>Minutes Added</b></h2>

            <br/><br/>    
            {targets && targets.map((trgt, index) => ( 
                                  <div key={index}>
                                  
                                      <div className="row-display">
                                      <div className="col-lg-5">
                                      <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">EHR-ID: </label> 
                                             <label className="report-label ml-2">{trgt?.assigned_patient_id?.emrId}</label>
                                          </div>

                                        <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Name: </label> 
                                             <label className="report-label ml-2"> {trgt?.assigned_patient_id?.firstname} {trgt?.assigned_patient_id?.lastname}</label>
                                          </div>

                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">DOB: </label> 
                                             <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                          </div>

                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Gender: </label> 
                                             <label className="report-label ml-2">{trgt?.assigned_patient_id?.gender === "male" ? 'Male' : 'Female'}</label>
                                          </div>                                         
                                      </div>

                                      {/* Time added details */}
                                      <div className="col-lg-4 text-center">
                                        <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Start Date: </label> 
                                             <label className="report-label ml-2"> {trgt?.startDate || 'N/A' }</label>
                                          </div>

                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">End Date: </label> 
                                             <label className="report-label ml-2">{trgt?.endDate || 'N/A' }</label>
                                          </div>
 
                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Start Time: </label> 
                                             <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                          </div>
 
                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">End Time: </label> 
                                             <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                          </div>

                                      </div>
                                    
                                    {/* Notes added */}
                                      <div className="col-lg-3 text-center">
                                        <div className="pl-4 pr-4">
                                             <label className="profile-label">Minutes Added : </label> 
                                             <h1> {trgt?.timeSpentInMinutes} </h1>
                                             {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                          </div>
                                      </div>
            
                                      </div>

                                     <br/><br/>   
                                      <div className="row-display-secondary">
                                           
                                            {/* <div className="mt-3 mr-3">
                                                <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                            </div> */}
                                            <div>  
                                                
                                                <b>{trgt?.assigned_hr_id?.lastname} {trgt?.assigned_hr_id?.firstname}</b><br/>
                                                                    
                                                <p className="mt-1 mr-3" style={{textAlign: 'justify'}}>{trgt?.conclusion} <br/> 
                                                <p className="mt-1">{moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                                </p>
                                            </div>
                                        </div>
                                    <hr />
                                  </div>
                              ))}
        </div>
      )
    }
}