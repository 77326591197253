    import { useEffect, useState, useRef } from 'react';
    import MetaData from '../../layouts/MetaData';
    import  HRSidebar from '../../components/HR/HRSidebar';
    import HRTopBar from '../../components/HR/HRTopbar';
    import { getTimeReport, getTimeReportCCM } from '../../actions/HRActions';
    import { getDoctorCCMPatients, getDoctorPatients } from '../../actions/adminActions';
    import {useSelector, useDispatch} from 'react-redux';
    import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
    import moment from 'moment';
    import { Link, useHistory } from 'react-router-dom';
    import { useAlert } from 'react-alert';
    import Loader from '../../layouts/Loader';
    import TimeReportPDF from '../../components/TimeReportPDF';
    import { useReactToPrint } from 'react-to-print';
    import patientProfileImg from '../../assets/Images/doctorIcon.png';
    

    const TimeReport = () => {
        const dispatch = useDispatch();
        const alert = useAlert();
        const history = useHistory();

        const { doctorpatients } = useSelector(state => state.docPatients);

        const [orgId, setOrgId] = useState(null);
        const [drId, setDrId] = useState(null);

        const componentRef = useRef();


        const handlePrint = useReactToPrint({
            pageStyle:"A5",
            documentTitle:  "Time Report",
            content: () => componentRef.current,
        });
    
        const { orgsAssigned, assignedDoctors } = useSelector(state => state.hrStats);
        const [patientId, setPatientId] = useState('');
        const [month, setMonth] = useState(moment().format('MM'));
        
        const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
        const [endMonth, setEndMonth] = useState(moment().format('MM'));

        const [year, setYear] = useState("2024");
        const [reportCategory, setReportCategory] = useState('RPM');

        const [resPerPage, setResPerPage] = useState(600);
       
        const {  loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);


        const downloadFile = (fileName) => {
            window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
        }

        useEffect(() => {

            dispatch({ type: RESET_TIME_REPORT_DATA });
            
            {reportCategory === 'RPM' ? 
                dispatch(getDoctorPatients(drId)) : 
                dispatch(getDoctorCCMPatients(drId));
            };
            

        }, [dispatch, reportCategory, orgId, drId])

        const submitHandler = () => {
            if(!patientId) {
                alert.error('Please select patient');
                return;
            } else {
                if(reportCategory === 'RPM'){
                    dispatch(getTimeReport(patientId, month, year, null, resPerPage));
                } else {
                    dispatch(getTimeReportCCM(patientId, startMonth, endMonth, year, resPerPage)); 
                }
            }    
        }

        const resetData = () => {
            dispatch({ type: RESET_TIME_REPORT_DATA });
            setPatientId("")
        }
    return <>
        <MetaData title="Targeted Minutes Report" />
                <HRSidebar />
                
                <section className="home-section">
                    {/* TopBar */}  
                    <HRTopBar />

                    <br/>
                    <div className="shadow-lg m-2 background-white rounded">        
                            <div className="home-content">
                                <div>
                                    <div className="row-display p-2 mr-2 ml-2">
                                        
                                    <h5 className='title pt-3'>
                                        <i className='bx bx-user'></i> 
                                        &nbsp; Individual Patient Time Report
                                    </h5>

                                        <div className="row-display m-1">
                                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                                <i className='bx bx-arrow-back'></i>
                                            </button>

                                            &nbsp;&nbsp;
                                            <Link to="/Nurse/MonthlyTimeReport" className="link mt-2">
                                                   Multiple Patients Time Report
                                            </Link>
                                            
                                            &nbsp;&nbsp;
                                            <Link to="/Nurse/Dashboard" className="btn btn-secondary btn-sm">
                                                    <i className='bx bxs-home'></i>
                                            </Link>

                                        </div>  
                                    </div>

                                    
                                    <div className="row-display mt-3 reportFormFields">
                                    <div className="col-md-12">
                                    <div className="row">
                                    <div className='col-md-3'>
                                        <label className='form-label'>Select Organization</label>
                                            <select className="form-control" 
                                                value={orgId} 
                                                onChange={e => setOrgId(e.target.value)}
                                                >
                                            <option value="null">Select Organization</option>
                                            {orgsAssigned && orgsAssigned?.length && orgsAssigned.map((org, index) => (
                                                <option key={org?._id} value={org?._id}>{org?.name}</option>
                                            ))}
                                            </select>
                                        </div>

                                        <div className='col-md-3'>
                                        <label className='form-label'>Select Doctor</label>
                                            <select className="form-control" 
                                                value={drId} 
                                                onChange={e => setDrId(e.target.value)}
                                                disabled={orgId ? false : true}
                                                >
                                                <option value="null">Select Doctor</option>
                                                {assignedDoctors && assignedDoctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) => (
                                                    <option key={doctor?._id} value={doctor?._id}>
                                                        {'Dr. ' +doctor?.lastname +' , '+ doctor?.firstname}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    <div className="col-md-3">     
                                    <label className="form-label" htmlFor="year">Mins. Category </label>
                                        <select 
                                                name="category" 
                                                className="form-control"
                                                value={reportCategory} 
                                                onChange={e => setReportCategory(e.target.value)}
                                            >
                                            <option value="RPM">RPM</option>
                                            <option value="CCM">CCM</option>
                                        </select>
                                    </div>

                                    <div className="col-md-3">
                                    <label className="form-label" htmlFor="from">Select Patient </label>
                                        <select 
                                            label="From" 
                                            name="from" 
                                            className="form-control"
                                            value={patientId}
                                            onChange={(e) => setPatientId(e.target.value)}
                                        >
                                        <option value="SelectPatient">Select Patient</option>
                                        {doctorpatients && doctorpatients.filter(item => item?.block === false).map((patient, index) => (
                                            <option key={index} value={patient._id}> {patient.lastname +" , "+ patient.firstname } &nbsp;&nbsp;  {moment(patient.DOB).format("MM/DD/YYYY")} </option>
                                        ))}
                                        </select>
                                    </div>
                                    
                                   
                                    {reportCategory === 'RPM' ? 
                                      <div className='col-md-3 mt-3'>
                                        <label className="form-label" htmlFor="month">Select Month </label>
                                        <select 
                                            name="month" 
                                            className="form-control"
                                            value={month} 
                                            onChange={e => setMonth(e.target.value)}
                                            >
                                            <option value="01">January</option>
                                            <option value="02">February</option>
                                            <option value="03">March</option>
                                            <option value="04">April</option>
                                            <option value="05">May</option>
                                            <option value="06">June</option>
                                            <option value="07">July</option>
                                            <option value="08">August</option>
                                            <option value="09">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                            </select>
                                        </div> :
                                        // CCM minutes 
                                    <>
                                    <div className='col-md-3 mt-3'>
                                    <label className="form-label" htmlFor="month">Start Month </label>
                                    <select 
                                        name="month" 
                                        className="form-control"
                                        value={startMonth} 
                                        onChange={e => setStartMonth(e.target.value)}
                                        >
                                        <option value="01">January - 26</option>
                                        <option value="02">February - 26</option>
                                        <option value="03">March - 26</option>
                                        <option value="04">April - 26</option>
                                        <option value="05">May - 26</option>
                                        <option value="06">June - 26</option>
                                        <option value="07">July - 26</option>
                                        <option value="08">August - 26</option>
                                        <option value="09">September - 26</option>
                                        <option value="10">October - 26</option>
                                        <option value="11">November - 26</option>
                                        <option value="12">December - 26</option>
                                        </select>
                                    </div>


                                    <div className="col-md-3 mt-3">
                                    <label className="form-label" htmlFor="month">End Month </label>
                                    <select 
                                        name="month" 
                                        className="form-control"
                                        value={endMonth} 
                                        onChange={e => setEndMonth(e.target.value)}
                                        >
                                        <option value="01">January - 25</option>
                                        <option value="02">February - 25</option>
                                        <option value="03">March - 25</option>
                                        <option value="04">April - 25</option>
                                        <option value="05">May - 25</option>
                                        <option value="06">June - 25</option>
                                        <option value="07">July - 25</option>
                                        <option value="08">August - 25</option>
                                        <option value="09">September - 25</option>
                                        <option value="10">October - 25</option>
                                        <option value="11">November - 25</option>
                                        <option value="12">December - 25</option>
                                        </select>
                                    </div>
                                    </>
                                    }            
                                    
                                <div className='col-md-3 mt-3'>     
                                    <label className="form-label" htmlFor="year">Select Year </label>
                                        <select 
                                        name="year" 
                                        className="form-control"
                                        value={year} 
                                        onChange={e => setYear(e.target.value)}
                                        >
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                    </select>
                                </div>
                                
                                <div className='col-md-3 mt-5'>     
                                    <button  
                                        name="submit" 
                                        type="submit" 
                                        className="edit-pill-2"
                                        onClick={submitHandler}
                                        disabled={patientId && orgId && drId ? false : true}
                                    > Generate Report
                                    </button>
                                </div>

                                </div>  {/* First Row Ends Here */}
                                </div>
                                </div>

                            
                                <br/>
                                

                                {/* Targets Display */}
                                {loading ? <Loader /> : <>
                                
                                {targets && targets.length > 0 ? <>
                                    <br/><br/>
                                    <div className='container'>
                                        <div className="row-display">
                                            <h5 className='title'>
                                                <i className='bx bxs-watch'></i> 
                                                    &nbsp;Generated Report Result 
                                            </h5>

                                            <div>
                                            <div>
                                                <div className="row">
                                                <div
                                                    style={{ display: "none" }} // This make ComponentToPrint show   only while printing
                                                > 
                                                <TimeReportPDF ref={componentRef} 
                                                    targets={targets}
                                                    interactiveMinutes={totalInteractiveMinutes}
                                                    nonInteractiveMinutes={totalNonInteractiveMinutes}
                                                    timeSpent={totalTime}
                                                    month= {month}
                                                    year= {year}
                                                    category={reportCategory}
                                                    endMonth={endMonth}
                                                />
                                                </div>
                                                
                                                <button onClick={handlePrint} 
                                                    className="edit-pill">
                                                        <i className='bx bxs-file-pdf' style={{color:'#F40F02'}}></i> Download PDF
                                                </button> &nbsp;&nbsp;
                                                
                                                <button className="edit-pill" onClick={resetData}>Reset</button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                            <div className="row-display">
                                                <div className="col-md-4 card p-4 m-2 text-center">
                                                    <h2>{totalTime} mins</h2>
                                                    <p><b>Total Time Spent</b></p>
                                                </div>

                                                <div className="col-md-4 card p-4 m-2 text-center">
                                                    <h2>{totalInteractiveMinutes} mins</h2>
                                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                                </div>

                                                <div className="col-md-4 card p-4 m-2 text-center">
                                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                                </div> 
                                            </div>   
                                    </div>   
                                    <br />
                    
                                    {targets && targets.reverse().map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Name: </label> 
                                                <label className="profile-label ml-2"> {trgt?.assigned_patient_id?.lastname +" , "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">D.O.B: </label> 
                                                <label className="report-label ">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">S / D: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">E / D: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S / T: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.startTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E / T: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.endTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                            </> : null}
                                            </div>
                                        </div>
                                        </div>

                                        {trgt?.clinicalFollowUp ? <div className="container-fluid">
                                            <h5 className='title'>Clinical FollowUp</h5>
                                            <label className="profile-label">Q. How have you been managing your health ?</label>
                                            <p>{trgt?.clinicalFollowUp?.healthManagement}</p> 
                                            <br />
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any new problems or conditions ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isNewProblem === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. New Problems or Conditions Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.newProblemDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isWorseningConditions === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.worseningConditionsDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 2 */}
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problems Identified ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isMedicationProblems === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problem Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationProblemsDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall in last 30 days ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentFall === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentFallDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 3 */}
                                                <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Visit ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentlyHospitalized === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Details  ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentlyHospitalizedDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Request ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequest === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequestDetails}</p>
                                                </div>
                                            </div>
                                            
                                            <br />
                                            <label className="profile-label">Q. Any Additional Notes ?</label>
                                            <p>{trgt?.clinicalFollowUp?.additionalNotes}</p>
                                            

                                        </div> : null}
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                    <b className='text-white' 
                                                        style={{letterSpacing: '1px'}}> 
                                                        {trgt?.assigned_assistant_id ? 
                                                            trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname 
                                                            : trgt?.assigned_doctor_id ? 'Dr. '+ trgt?.assigned_doctor_id?.lastname +", "+ trgt?.assigned_doctor_id?.firstname 
                                                            : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname
                                                            }
                                                    </b>
                                                    <br/>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1">
                                                        {moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>

                                ))}
                                </> : ''}
                                </>}
                                
                            </div>
                        </div>
                    </div>
                </section>
    </>
    };

    export default TimeReport;
